<template>
  <section class="ma-5">
    <v-row align="center">
      <p class="text-h5 secondary--text">Listado de contralores</p>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="buscador_nombre"
          outlined
          label="Nombre"
          clearable
          @click:clear="filterNombre"
          @keyup="filterNombre"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          outlined
          v-model="buscador_institucion"
          label="Institución"
          item-value="id"
          item-text="nombre"
          :items="institucionesList"
          clearable
          @change="getContralores"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table-component
          v-models:select="perPage"
          v-models:pagina="page"
          :headers="headers"
          :items="items"
          @paginar="paginate"
          :total_registros="totalRows"
        >
          <template v-slot:[`item.nombre`]="{ item }">
            {{ item.primer_nombre }}
            {{ item.segundo_nombre }}
            {{ item.tercer_nombre }}
            {{ item.primer_apellido }}
            {{ item.segundo_apellido }}
            {{ item.apellido_casada }}
          </template>
          <template v-slot:[`item.institucion`]="{ item }">
            {{ item.institucion?.unidades?.institucion }}
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="(showDialog = true), (info = item)"
                  v-bind="attrs"
                  v-on="on"
                  large
                  color="primary"
                >
                  mdi-eye
                </v-icon>
              </template>

              <span>Ver</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>

      <v-dialog persistent max-width="1000" class="modal" v-model="showDialog">
        <v-card max-width="1000" max-height="1050">
          <v-btn @click="showDialog = false" icon color="secondary">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <v-card-text>
            <section>
              <v-row no-gutters>
                <p class="text-h5 secondary--text text-capitalize">
                  {{ info.primer_nombre }}
                  {{ info.segundo_nombre }}
                  {{ info.tercer_nombre }}
                  {{ info.primer_apellido }}
                  {{ info.segundo_apellido }}
                  {{ info.apellido_casada }}
                </p>
              </v-row>
              <v-row no-gutters v-if="info.documentos">
                <v-col cols="6">
                  <p class="mb-0">Tipo de documento de identidad</p>
                  <p class="secondary--text text-subtitle-1">
                    {{ info.documentos[0]?.tipo_documento || "" }}
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="mb-0">Número de documento de identidad</p>
                  <p class="secondary--text text-subtitle-1">
                    {{ info.documentos[0]?.numero_documento || "" }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <p class="mb-0">Correo electrónico</p>
                  <p class="secondary--text text-subtitle-1">
                    {{ info.correo }}
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="mb-0">Teléfono</p>
                  <p class="secondary--text text-subtitle-1">
                    {{ info.telefono }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <p class="mb-0">Profesión</p>
                  <p class="secondary--text text-subtitle-1">
                    {{ info.profesion }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <p class="mb-0">Cargo funcional</p>
                  <p class="secondary--text text-subtitle-1">
                    {{ info.usuario?.cargo_funcional }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <p class="mb-0">
                    División, Unidad, Departamento o Área a la que pertenece
                  </p>
                  <p class="secondary--text text-subtitle-1">
                    {{ info.institucion?.unidades?.nombre }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <p class="mb-0">Fecha de caducidad</p>
                  <p class="secondary--text text-subtitle-1">
                    {{ moment(info.usuario?.fecha_caducidad).format("DD-MM-YYYY") }}
                  </p>
                </v-col>
              </v-row>
            </section>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              class="text-capitalize mx-8 mb-2 mb-sm-0"
              outlined
              @click="showDialog = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";

export default {
  name: "ContraloresView",
  components: {
    DataTableComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Institución",
        value: "institucion",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    showDialog: false,
    info: {},
    institucionesList: [],
    buscador_nombre: null,
    buscador_institucion: null,
    timer: null,
  }),
  methods: {
    async getContralores() {
      const { status, data, headers } =
        await this.services.Contralores.getContraloresList({
          page: this.page,
          per_page: this.perPage,
          id_institucion: this.buscador_institucion,
          busqueda: this.buscador_nombre,
        });

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getContralores();
    },
    async getInstituciones() {
      const response = await this.services.Usuarios.getInstituciones();
      this.institucionesList = response.data;
    },
    filterNombre() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(async () => this.getContralores(), 500);
    },
  },
  created() {
    this.getContralores();
    this.getInstituciones();
  },
};
</script>
